




















import { getCurrentInstance, defineComponent } from '@vue/composition-api'

import { CustomProgressBarProps } from './CustomProgressBar.contracts'
import { customProgressBarProps, useCustomProgressBar } from './CustomProgressBar.hooks'

/**
 * @author Łukasz Bęben <lukasz.beben@movecloser.pl>
 */
export const CustomProgressBar = defineComponent({
  name: 'CustomProgressBar',
  props: customProgressBarProps,

  setup (props: CustomProgressBarProps) {
    return useCustomProgressBar(
      props,
      getCurrentInstance()
    )
  }
})

export default CustomProgressBar

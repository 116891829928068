import { ComponentInternalInstance, PropType, computed, toRef } from '@vue/composition-api'

import { canHaveThemeProp, useThemeClass } from '../../composables'
import { ComponentObjectPropsOptions } from '../../vue-api'

import { CustomProgressBarProps, Marker, UseCustomProgressBarProvides } from './CustomProgressBar.contracts'
import { capacityCustomProgressBarThemeClassRegistry } from './CustomProgressBar.config'

/**
 * @author Łukasz Bęben <lukasz.beben@movecloser.pl>
 */
export const customProgressBarProps: ComponentObjectPropsOptions<CustomProgressBarProps> = {
  /**
   * @see CustomProgressBarProps.theme
   */
  ...canHaveThemeProp,

  /**
   * @see CustomProgressBarProps.maxValue
   */
  label: {
    type: String,
    required: false,
    default: ''
  },

  /**
   * @see CustomProgressBarProps.maxValue
   */
  maxValue: {
    type: Number,
    required: false,
    default: 100
  },

  /**
   * @see CustomProgressBarProps.value
   */
  value: {
    type: Number,
    required: true
  },

  /**
   * @see CustomProgressBarProps.markers
   */
  markers: {
    type: Array as PropType<Array<Marker>>,
    required: false,
    default: []
  }
}

/**
 * @author Łukasz Bęben <lukasz.beben@movecloser.pl>
 */
export const useCustomProgressBar = (
  props: CustomProgressBarProps,
  initialInstance: ComponentInternalInstance | null
): UseCustomProgressBarProvides => {
  if (!initialInstance) {
    throw new Error('No component instance is found!')
  }

  const { type } = initialInstance

  const themeClass = useThemeClass(toRef(props, 'theme'), capacityCustomProgressBarThemeClassRegistry, `${type._componentTag}`)

  const activeMarker = computed(() => {
    return props?.markers?.filter(marker => marker.value <= props.value).at(-1)
  })

  return { themeClass, activeMarker }
}
